"use client";

import React, { ReactNode, SyntheticEvent, useCallback, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { grey } from "@mui/material/colors";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import {
  MhcGeographyEnum,
  MhcLocationWithoutGeoJsonFragment,
  MhcTopicCategoryEnum
} from "graphqlApi/types";

import { DEFAULT_COLUMN_SPACING } from "layout/configuration";
import { STATIC_LOCATION_IDS } from "./constants";
import { PageCategories, TopicPage } from "../../util/topicIndexStaticProps";
import { categoryFormatter, filterItems } from "./util";
import { humanize, titleize } from "common/util/helpers";
import useParams, { ParamObject } from "common/util/hooks/useParams";
import { MhcIconTypeMap } from "common/util/mui";
import { GeographyNumberValueEncoding } from "common/util/sortGeographiesBySize";

import { baseAutoCompleteSX } from "common/components/LocationPicker/LocationPicker";
import { Picker, PickerItem } from "modules/Resources/indicator-browser/components/Picker";
import TopicTableChildren from "./TopicTableChildren";
import TopicTableNotes from "./TopicTableNotes";
import TopicTableRow from "./TopicTableRow";

const defaultParams: Record<string, string> = {
  isNew: "1",
  isInReview: "0"
};

export interface TopicTableProps {
  topics: Record<PageCategories, TopicPage[]>;
  locations: MhcLocationWithoutGeoJsonFragment[];
}

const TopicTable: React.FC<TopicTableProps> = ({ topics, locations }) => {
  const [params, setParams, getParams] = useParams(null);
  const initiallySelectedLocation = useMemo(() => {
    const currentParams = getParams();
    if (currentParams.location) {
      const _location = locations.find(({ id }) => id === currentParams.location);
      return {
        name: _location?.name ?? "Delaware",
        id: _location?.id ?? "state",
        geography: _location?.geography ?? MhcGeographyEnum.State
      } as PickerItem;
    }
    return {
      name: "Delaware",
      id: "state",
      geography: MhcGeographyEnum.State
    } as PickerItem;
  }, [getParams, locations]);
  const [location, setLocation] = useState<PickerItem>(initiallySelectedLocation);
  const [previousLocation, setPreviousLocation] = useState<PickerItem>(initiallySelectedLocation);

  const sortedLocations = useMemo(() => {
    if (!locations?.length) return [];
    const sortedLocations = [...locations];
    return sortedLocations
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .sort((a, b) =>
        GeographyNumberValueEncoding[a.geography as MhcGeographyEnum] <
        GeographyNumberValueEncoding[b.geography as MhcGeographyEnum]
          ? 1
          : -1
      );
  }, [locations]);
  const showLocationWarning = useMemo(() => !STATIC_LOCATION_IDS.includes(location.id), [location]);

  const flatTopics = useMemo(() => Object.values(topics).flat(), [topics]);

  const topicPages = useMemo(() => {
    let previousLocationId = previousLocation.id;
    if (initiallySelectedLocation.id !== "state") {
      previousLocationId = "state";
    }
    if (previousLocationId === location.id) return topics;
    const updateLocationOfPages = (pages: TopicPage[]): TopicPage[] => {
      return pages.map((page) => {
        return {
          ...page,
          href: page.href?.replace(previousLocationId, location.id) ?? "",
          location: location.id,
          children: page.children ? updateLocationOfPages(page.children) : []
        };
      });
    };
    return groupBy(updateLocationOfPages(flatTopics), "category") as TopicTableProps["topics"];
  }, [location, flatTopics, previousLocation.id, topics, initiallySelectedLocation.id]);

  const initialTopicList = useMemo(() => {
    return groupBy(
      filterItems(Object.values(topicPages).flat(), (topic) => topic.isNew === true),
      "category"
    );
  }, [topicPages]);

  // Filters
  const searchValue = useMemo(() => (params && "search" in params ? params.search : ""), [params]);
  const isNewFilter = useMemo(() => (params && "isNew" in params ? params.isNew : ""), [params]);
  const isInReviewFilter = useMemo(
    () => (params && "isInReview" in params ? params.isInReview : ""),
    [params]
  );

  const filteredTopics = useMemo(() => {
    let _topics: TopicPage[] | null = null;
    if ((searchValue && searchValue.length > 2) || isInReviewFilter === "1") {
      _topics = filterItems(
        Object.values(topicPages).flat(),
        (topic) =>
          (topic.name?.toLowerCase().includes(searchValue?.toLowerCase() ?? "") ?? true) &&
          (isNewFilter === "0" ? !topic.isNew || topic.isNew : true) &&
          (isInReviewFilter === "1" ? topic.isInReview === true : true)
      );
    } else if (isNewFilter === "0") {
      return topicPages;
    }

    if (_topics !== null) {
      return groupBy(_topics, "category") as TopicTableProps["topics"];
    }
    return initialTopicList;
  }, [initialTopicList, searchValue, isNewFilter, isInReviewFilter, topicPages]);

  const handleToggle = useCallback(
    (prop: string) => {
      let value = defaultParams[prop];
      if (params && (params as ParamObject)[prop] !== undefined) {
        value = (params as ParamObject)[prop];
      }
      setParams({ ...params, [prop]: value === "0" ? "1" : "0" });
    },
    [params, setParams]
  );

  const handleSearch = useCallback(
    (event: SyntheticEvent) => {
      const value = (event.target as HTMLInputElement).value;
      setParams({ ...getParams(), search: value });
    },
    [setParams, getParams]
  );

  return (
    <Box mb={10}>
      <Grid container display="flex" justifyContent="center">
        <Grid item container xs={12} lg={12} spacing={DEFAULT_COLUMN_SPACING}>
          <Grid item xs={12} lg={8}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              mb={4}
              gap={4}
            >
              <Typography variant="h2">All Topics and Pages</Typography>
              {sortedLocations.length > 0 && (
                <Picker
                  collectionName="location"
                  options={sortedLocations}
                  groupBy={(option: PickerItem) =>
                    "geography" in option ? titleize(humanize(option.geography ?? "")) : ""
                  }
                  onChange={(event: SyntheticEvent, item: PickerItem | null) => {
                    setPreviousLocation(location);
                    if (item?.id) {
                      setLocation(item);
                      setParams({ ...params, location: item?.id });
                    }
                  }}
                  initiallySelectedItem={location}
                  sx={{
                    ...(baseAutoCompleteSX as SxProps),
                    flex: 1,
                    minWidth: { xs: "100%", md: "auto" },
                    m: 0
                  }}
                  textFieldProps={{ size: "medium", label: "Selected location" }}
                />
              )}
            </Stack>
            <Divider sx={{ mb: 4 }} />
            {showLocationWarning && (
              <Alert sx={{ mb: 2 }} severity="warning">
                <Typography fontWeight={700} component="span">
                  Pages may load slowly
                </Typography>{" "}
                <Typography component="span">
                  &mdash; pages for selected location ({location.name}) are not built during
                  deployment.
                </Typography>
              </Alert>
            )}
            <Box mb={2}>
              <Stack
                direction="row"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                gap={{ xs: 2, md: 4 }}
              >
                <TextField
                  value={searchValue}
                  onChange={handleSearch}
                  label="Search topics and pages"
                  size="small"
                  sx={{ ...(baseAutoCompleteSX as SxProps), flexGrow: 1, minWidth: "auto", m: 0 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <Stack direction="row" flexWrap="wrap">
                  <FormControlLabel
                    control={<Switch defaultChecked onChange={() => handleToggle("isNew")} />}
                    label="Only show new pages"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => handleToggle("isInReview")}
                        checked={isInReviewFilter === "1" ? true : false}
                      />
                    }
                    label="Only show pages in review"
                  />
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container display="flex" justifyContent="center">
        <Grid item container xs={12} lg={12} spacing={DEFAULT_COLUMN_SPACING}>
          <Grid item xs={12} lg={8}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="topic table" size="small">
                <TableHead>
                  <TableRow sx={{ background: grey[100] }}>
                    <TableCell component="th" scope="row" sx={{ py: 2 }}>
                      Name
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ py: 2, textAlign: "center" }}>
                      In Review?
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ py: 2, textAlign: "center" }}>
                      On Vercel
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ py: 2, textAlign: "center" }}>
                      In Development?
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(filteredTopics).map(([categoryId, categoryTopics]) => (
                    <React.Fragment key={categoryFormatter(categoryId as MhcTopicCategoryEnum)}>
                      <TableRow sx={{ background: grey[50] }}>
                        <TableCell component="th" scope="row" sx={{ py: 1 }} colSpan={4}>
                          <Typography fontWeight={700}>
                            {categoryFormatter(categoryId as MhcTopicCategoryEnum)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {(sortBy(categoryTopics, ["parent", "name"]) as TopicPage[]).map(
                        (topic, i) => (
                          <React.Fragment
                            key={`${topic?.name ?? ""} ${i}`.toLowerCase().replace(" ", "-")}
                          >
                            <TopicTableRow
                              {...topic}
                              level={1}
                              Icon={(MhcIconTypeMap[topic.slug ?? ""] ?? null) as ReactNode}
                            />
                            <TopicTableChildren
                              childTopics={topic.children as TopicPage[]}
                              level={2}
                            />
                          </React.Fragment>
                        )
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TopicTableNotes
              locationClickCallback={(newLocation) => {
                const {
                  id,
                  name = "Delaware",
                  geography = MhcGeographyEnum.State
                } = locations.find(({ id }) => id === newLocation) ?? {};
                if (!id) return;
                setPreviousLocation(location);
                setLocation({ id, name, geography });
                setParams({ ...params, location: id });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopicTable;
