import { Box } from "@mui/material";
import groupBy from "lodash/groupBy";

import { NextPageWithLayout } from "common/util/nextHelpers";
import topicIndexStaticProps, { TopicIndexProps } from "modules/Topics/util/topicIndexStaticProps";

import PageLoadingIndicator from "common/components/PageLoadingIndicator";
import TopicTable, { TopicTableProps } from "modules/Topics/components/TopicTable/TopicTable";

export const getStaticProps = topicIndexStaticProps;

const HomePage: NextPageWithLayout<TopicIndexProps> = ({ topics, locations }) => {
  return (
    <Box position="relative">
      <PageLoadingIndicator message="Selected topic may take several seconds to load..." />
      <TopicTable
        topics={groupBy(topics, "category") as TopicTableProps["topics"]}
        locations={locations}
      />
    </Box>
  );
};

export default HomePage;
