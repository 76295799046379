import React, { ReactNode } from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Stack, TableCell, TableRow } from "@mui/material";
import { yellow } from "@mui/material/colors";

import { TopicPage } from "modules/Topics/util/topicIndexStaticProps";

import MhcTooltip from "common/components/MhcTooltip";
import TableCellContent from "./TableCellContent";

interface TopicTableRowProps extends Partial<TopicPage> {
  isChild?: boolean;
  level?: number;
  Icon?: ReactNode | null;
}

const TopicTableRow: React.FC<TopicTableRowProps> = ({
  name,
  href,
  level = 1,
  isInReview,
  isNew,
  description,
  parent,
  isInDevelopment
}) => {
  return (
    <TableRow
      key={name}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        background: isInReview === true ? yellow[50] : "inherit"
      }}
    >
      <TableCell component="td" sx={{ pl: level > 1 ? 2.5 * level : 2 }} align="center">
        <Stack direction="row" gap={1} alignItems="center">
          <TableCellContent
            href={href}
            name={name}
            description={description}
            parent={parent}
            isNew={isNew}
          />
        </Stack>
      </TableCell>
      <TableCell component="td" sx={{ textAlign: "center" }} align="center">
        {isInReview === true && (
          <MhcTooltip title="In client review">
            <PersonSearchIcon />
          </MhcTooltip>
        )}
      </TableCell>
      <TableCell component="td" sx={{ textAlign: "center" }} align="center">
        {isNew === true && (
          <MhcTooltip title="On Vercel — is a new page in the new frontend.">
            <FiberNewIcon />
          </MhcTooltip>
        )}
      </TableCell>
      <TableCell component="td" sx={{ textAlign: "center" }} align="center">
        {isInDevelopment === true && (
          <MhcTooltip title="In Development">
            <EngineeringIcon />
          </MhcTooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default TopicTableRow;
