"use client";

import { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, SxProps, TextField, TextFieldProps } from "@mui/material";

import { MhcLocationWithoutGeoJsonFragment, MhcStatIdentifierBaseFragment } from "graphqlApi/types";

import { determineLabel } from "modules/Resources/util";

import { filterOptions, isOptionEqualToValue } from "..";

export interface GenericPickerItem {
  id: string;
  name: string;
}

export type PickerItem =
  | MhcLocationWithoutGeoJsonFragment
  | MhcStatIdentifierBaseFragment
  | GenericPickerItem;
interface Props {
  initiallySelectedItem: PickerItem;
  onChange: (event: SyntheticEvent, _statIdentifier: PickerItem) => void;
  options: PickerItem[];
  groupBy?: (option: PickerItem) => string;
  collectionName: string;
  sx?: SxProps;
  textFieldProps?: Partial<TextFieldProps>;
}

export const Picker: React.FC<Props> = ({
  onChange,
  initiallySelectedItem,
  options,
  groupBy,
  collectionName,
  sx = {},
  textFieldProps = {}
}) => {
  const [selectedItem, setSelectedItem] = useState<PickerItem>(initiallySelectedItem);
  useEffect(() => {
    setSelectedItem(initiallySelectedItem);
  }, [initiallySelectedItem]);
  return (
    <Autocomplete
      autoComplete
      clearOnEscape={false}
      filterOptions={filterOptions}
      getOptionLabel={determineLabel}
      groupBy={groupBy}
      includeInputInList
      noOptionsText={`No ${collectionName}s found`}
      onChange={(event: SyntheticEvent, newSelectedItem: PickerItem | null) => {
        if (!newSelectedItem || newSelectedItem?.id === selectedItem?.id) return;
        setSelectedItem(newSelectedItem);
        onChange(event, newSelectedItem);
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Search for ${collectionName}`}
          variant="outlined"
          {...textFieldProps}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {determineLabel(option)}
          </li>
        );
      }}
      sx={{ mb: 4, maxWidth: 500, ...sx }}
      value={selectedItem}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
