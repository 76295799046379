import { getMhcStatIdentifier } from "graphqlApi/legacy/mhcClient";

import { MhcStatIdentifierFragment } from "graphqlApi/types";
import { FetchKpiOptions } from "modules/Topics/util/fetchingFunctions/kpi/types";

import { fetchKpiProps } from "modules/Topics/util/fetchingFunctions/kpi";

import { PickerItem } from "./indicator-browser/components/Picker";
import { KpiProps } from "common/components/KPI";

export interface KpiReturnProps {
  kpi: KpiProps | null;
  statIdentifier: MhcStatIdentifierFragment | null;
  error: string | null;
}

export const fetchKpiData = async (
  statId: string,
  locationId: string,
  options: FetchKpiOptions = {}
): Promise<KpiReturnProps> => {
  try {
    const { kpis = [] } = await fetchKpiProps({
      statIds: [statId],
      locationId,
      options: {
        ...options,
        statIdTopicInfoDictionary: {
          [statId]: {
            path: `/data-dictionary/indicators/${statId}`,
            name: statId,
            linkText: "View data dictionary entry for this indicator"
          }
        }
      }
    });
    return {
      statIdentifier: await getMhcStatIdentifier({ statId, locationId }),
      kpi: kpis.length ? kpis[0] ?? null : null,
      error: null
    };
  } catch (error: unknown) {
    return { error: error as string, kpi: null, statIdentifier: null };
  }
};

// Update this once `statType` is in
export const determineLabel = ({ id, name }: PickerItem) => {
  let optionName = name;
  if (!id) return optionName;
  switch (true) {
    case id.includes("COUNT"):
      optionName += " (Count)";
      break;
    case id.includes("CRUDE_RATE"):
      optionName += " (Rate)";
      break;
    case id.includes("RATE"):
      optionName += " (Age-Adjusted Rate)";
      break;
  }
  return optionName;
};
