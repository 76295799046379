"use client";

import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";

import { logError } from "common/util/consoleHelpers";

export type ParamObject = Record<string, string>;

type Return = [ParamObject, (params: ParamObject) => void, () => ParamObject, () => void];

// This is a rough implementation of `useSearchParams` from `react-router-dom` or Next v13's
// version of the hook. It helps store state in the URL.
// We should abandon this after we update to v13 and replace instances with the
// framework's version.

const useParams = (initialParamObject: ParamObject | null): Return => {
  const [_params, _setParams] = useState<ParamObject | null>(initialParamObject);
  const router = useRouter();

  const setParams = useCallback(
    (paramObject: ParamObject | null) => {
      if (!paramObject) return;
      _setParams(paramObject);
      if (paramObject) {
        router.query = paramObject;
        router
          .push(router, undefined, {})
          .then(() => router.reload())
          .catch(logError);
      }
    },
    [router]
  );

  const clearParams = useCallback(() => {
    _setParams({});
    router.query = {};
    router
      .push(router, undefined, {})
      .then(() => router.reload())
      .catch(logError);
  }, [router]);

  const getParams = useCallback(() => {
    if (typeof window === "undefined") return {};
    const urlParams = new URLSearchParams(window.location.search);
    const paramObject: ParamObject = {};
    for (const [key, value] of urlParams.entries()) {
      paramObject[key] = value;
    }
    return paramObject;
  }, []);

  useEffect(() => {
    if (!initialParamObject) {
      const paramObject = getParams();
      if (!paramObject || Object.keys(paramObject).length === 0) return;
      _setParams(paramObject);
      return;
    }
    _setParams(initialParamObject);
    setParams(initialParamObject);
  }, [setParams, initialParamObject, getParams]);

  return [_params as ParamObject, setParams, getParams, clearParams];
};

export default useParams;
