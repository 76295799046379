"use client";

import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Link as MuiLink, Stack, Typography } from "@mui/material";

import { envIsDev } from "common/util/envHelpers";
import { TopicPage } from "modules/Topics/util/topicIndexStaticProps";

import { useNavigationStore } from "common/state/useNavigationStore";
import MhcTooltip from "common/components/MhcTooltip";

const TableCellContent: React.FC<Partial<TopicPage>> = ({
  href,
  name,
  description,
  parent,
  isNew
}) => {
  const setIsNavigating = useNavigationStore((store) => store.setIsNavigating);
  const domain = (typeof window !== "undefined" && window.location?.hostname) || "";
  // Since the two apps (rails and next.js) are hosted separately, some links will not be accessible. To save confusion, we will only render links that are accessible.
  const renderAsLink = href && (isNew || (href?.includes(domain) && !envIsDev()));
  if (renderAsLink) {
    return (
      <>
        <MuiLink onClick={() => setIsNavigating(true)} component="a" href={href}>
          <Stack direction="row" gap={0.5} alignItems="center">
            <Typography>{name}</Typography>
            {description && (
              <MhcTooltip title={description}>
                <InfoIcon fontSize="inherit" color="action" />
              </MhcTooltip>
            )}
          </Stack>
        </MuiLink>
        {parent && (
          <Typography variant="body2" color="light.secondary">
            {" "}
            ({parent})
          </Typography>
        )}
      </>
    );
  }
  return (
    <MhcTooltip title={"This page is hosted on the old app and not accessible in this environment"}>
      <>
        <Typography>{name}</Typography>
        {parent && (
          <Typography variant="body2" color="light.secondary">
            ({parent})
          </Typography>
        )}
      </>
    </MhcTooltip>
  );
};

export default TableCellContent;
