import { GetStaticProps } from "next";
import { createFilterOptions } from "@mui/material/Autocomplete";
import defaultsDeep from "lodash/defaultsDeep";
import {
  getAllLocationsFromGeographies,
  getLocationWithGeoJson,
  getMhcDateSeries,
  getMhcLocationStat,
  getMhcStatIdentifier
} from "graphqlApi/legacy/mhcClient";

import {
  MhcDateSeriesFragment,
  MhcGeographyEnum,
  MhcLocation,
  MhcLocationFragment,
  MhcLocationStatFragment,
  MhcPageFragment,
  MhcStatIdentifierFragment,
  MhcTopicCategoryEnum,
  MhcTopicTypeEnum
} from "graphqlApi/types";

import { fetchKpiData } from "../util";
import { logError } from "common/util/consoleHelpers";
import { envIsProd } from "common/util/envHelpers";
import { redirect } from "modules/Topics/util/fetchingFunctions";

import { PickerItem } from "./components/Picker";
import { KpiProps } from "common/components/KPI";

export interface InterfaceBrowserProps {
  location: MhcLocationFragment | null;
  statIdentifier?: MhcStatIdentifierFragment | null;
  locationStat: MhcLocationStatFragment | null;
  locationDateSeries: MhcDateSeriesFragment | null;
  kpiProps?: KpiProps | null;
  error?: string | null;
  locationsForSwitcher?: MhcLocation[];
}

export const getIndicatorBrowserStaticPaths = async () => {
  const locations = await getAllLocationsFromGeographies([MhcGeographyEnum.State]);
  return {
    paths: locations.map(({ id: locationId }) => ({ params: { locationId } })),
    fallback: "blocking"
  };
};

export const getIndicatorBrowserStaticProps: GetStaticProps<
  InterfaceBrowserProps,
  { locationId?: string; indicatorId?: string }
> = async ({ params = {} }) => {
  const { locationId = "state", indicatorId = null } = params;
  let kpiProps = null;
  let locationStat = null;
  let locationDateSeries = null;
  let statIdentifier = null;
  let error = null;
  try {
    const location = await getLocationWithGeoJson({ locationId });

    if (indicatorId) {
      try {
        locationStat = await getMhcLocationStat({ statId: indicatorId, locationId });
        (locationDateSeries = await getMhcDateSeries({ statId: indicatorId, locationId })),
          (statIdentifier = await getMhcStatIdentifier({ statId: indicatorId }));
        const kpiData = await fetchKpiData(indicatorId, locationId, {
          granularity: statIdentifier.availableGranularities[0]
        });
        kpiProps = defaultsDeep(
          {
            enhancement: {
              showTarget: true
            }
          },
          kpiData.kpi
        );
      } catch (e: unknown) {
        error = e as string;
      }
    }
    return {
      props: { location, locationStat, locationDateSeries, statIdentifier, kpiProps, error },
      revalidate: false,
      notFound: envIsProd()
    };
  } catch (err) {
    logError(err);
    return redirect;
  }
};

export const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: ({ name, id, ...rest }: PickerItem) => {
    if ("groupName" in rest) {
      return name + (rest.groupName || "") + id;
    } else {
      return name + id;
    }
  }
});

export const isOptionEqualToValue = (a: PickerItem, b: PickerItem) => a?.id === b?.id;

export const indicatorBrowserTopic: MhcPageFragment = {
  __typename: "MhcTopic",
  category: MhcTopicCategoryEnum.Reference,
  href: "/portals/indicator-browser",
  id: "indicator-browser",
  name: "Indicator Browser",
  slug: "indicator-browser",
  topicType: MhcTopicTypeEnum.Topic
};
